import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl } from '../../../constants'
import { checkAuthorization } from '../../../helpers';
import { message } from 'antd'

export const getAddDashboardDetail = createAsyncThunk('getDashboardStatistics',
    async (object, { dispatch, getState }) => {

        const {userToken}= checkAuthorization()
        dispatch(gettingAddDashboardDetail())
        var myHeaders = new Headers();
        myHeaders.append("Authorization", userToken)
        myHeaders.append("Content-Type", "application/json")
        myHeaders.append("pageNO", object?.pageNo)
        myHeaders.append("recordsPerPage", object?.pageSize)
        var requestOptions = {
            method: 'Post',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({id: object?.order})
        };
        fetch(domainUrl + `/nurseDashboardAppointment`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result?.success) {
                    dispatch(adddashboardDetailResponse(result?.data))
                    dispatch(setPages({pageNo: object?.pageNo, pageSize: object?.pageSize, totalRecords: result?.totalRecords}))
                }
                else 
                    throw result?.message
            })
            .catch(error => { 
                dispatch(adddashboardDetailClear())
                message.error(error) 
            })
    }
)

const AddDashboardSlice = createSlice({
    name: 'dashboard',
    initialState: {
        data: null,
        loading: false,
        adddashboardDetail: null,
        loadingAddDashboardDetail: false,
        pagination:{
            pageNo:1,
            pageSize: 10,
            totalRecords:0,
        },
        filters: {
            title: '',
            order: 2,
        },
    },
    reducers: {
        getPatientStatistics: state => {
            state.loading=true
            state.data=null
        },
        statisticsResponse: (state, action)=>{
            state.data= action.payload
            state.loading= false
        },
        statisticsClear: (state)=>{
            state.data= null
            state.loading= false
        },
        gettingAddDashboardDetail: state => {
            state.loadingAddDashboardDetail=true
            state.adddashboardDetail=null
        },
        adddashboardDetailResponse: (state, action)=>{
            state.adddashboardDetail= action.payload
            state.loadingAddDashboardDetail= false
        },
        adddashboardDetailClear: (state)=>{
            state.adddashboardDetail= null
            state.loadingAddDashboardDetail= false
        },
        changeAdddashboardDetailFilters: (state, action) => {
            state.filters = action.payload
        },
        setPages:(state, action)=>{
            state.pagination= action.payload
        },
    },
})
export const { 
    gettingaddboxStatistics, statisticsResponse, statisticsClear,
    gettingAddDashboardDetail, adddashboardDetailResponse, adddashboardDetailClear,
    changeAdddashboardDetailFilters, setPages
} = AddDashboardSlice.actions;
export default AddDashboardSlice.reducer;