import { useState } from "react"
import { Space, Typography, Avatar} from "antd"
import {
    CaretDownOutlined,
    UserOutlined
} from '@ant-design/icons'
import UserProfileDrawer from "./UserProfileDrawer"

export const UserProfile = () => {
    const [visible, setVisible]= useState(false)
    return (
      <>
      <Space 
        style={{ cursor: 'pointer' }}
        onClick={()=> setVisible(true)}
      >
        <Avatar
          size={30}
          icon={<UserOutlined/>}
        />
      </Space>
      <UserProfileDrawer
        visible={visible}
        onClose={()=> setVisible(false)}
      />
    </>
    )
}