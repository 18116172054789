import React, { useState } from 'react'
import { Image, Space, Row, Col, Form, Typography,Dropdown, Card, Table, Button, Flex, Switch, Radio } from 'antd'
import { MyInput, ModuleTopHeading, ActionButton, SendMsgModal, AlertModal, ViewPateintDrawer, PatientByPrescriptionTable, PatientByAppointmentTable } from '../../components';
import { EyeOutlined, FilterOutlined } from '@ant-design/icons';

const { Text } = Typography


const Patients = () => {

    const [ value, setValue ] = useState(1)
    const [ titlechange, setTitleChange ] = useState('by Prescription')

    const onChange = (e) => {
        const status = e.target.value;
        setValue(status);
        setTitleChange(status === 1 ? 'by Prescription' : 'by Appointment')
    };

   

  return (
    <div>
        <Card className='radius-12 border0 shadow-in'>
            <Row gutter={[12,12]}>
                <Col span={12}>
                    <ModuleTopHeading
                        name={`Patients ${titlechange}` }
                    />
                </Col>
                <Col span={12}>
                    <Flex justify='end'>
                        <Radio.Group onChange={onChange} value={value}>
                            <Radio value={1}>Patients by Prescription</Radio>
                            <Radio value={2}>Patients by Appointment</Radio>
                        </Radio.Group>
                    </Flex>
                </Col>
                <Col span={24}>
                    {
                        value == 1 ?
                        <PatientByPrescriptionTable />
                        :
                        <PatientByAppointmentTable />
                    }
                </Col>
            </Row>
        </Card>
    </div>
  )
}

export {Patients}