
//login
import { login, forgotPassword, loginResponse, loginClear, AuthCheck, refreshToken } from "./action/Login"
//dashboard
import { getPatientPrescriptionDetail, changePatientPrescriptionDetailFilters, prescriptionpatientDetailResponse } from "./action/patientprescription"
import { getPatientAppointmentDetail, changePatientAppointmentDetailFilters } from "./action/patientappointment"
import { getAddCardDetail } from "./action/cardapi"
import { getChartapiDetail, changeAddChartDetailFilters } from "./action/chartapi"
import { getAddDashboardDetail, changeAdddashboardDetailFilters } from "./action/dashboard"
import { getAddServicepatientDetail, changeAddservicepatientDetailFilters } from "./action/prescriptionservicepatient"
import { getViewMedpresDetail } from "./action/viewmedicineprescription"


export const actionsApi = {
    login, 
    forgotPassword,
    loginResponse, 
    loginClear, 
    AuthCheck, 
    refreshToken,
    //patient prescription 
    changePatientPrescriptionDetailFilters,
    prescriptionpatientDetailResponse,
    getPatientPrescriptionDetail,
    // patient appointment
    getPatientAppointmentDetail,
    changePatientAppointmentDetailFilters,
    // dashboard
    getAddCardDetail,
    getChartapiDetail,
    changeAddChartDetailFilters,
    getAddDashboardDetail,
    changeAdddashboardDetailFilters,

    // service patient by id
    getAddServicepatientDetail,
    changeAddservicepatientDetailFilters,
    getViewMedpresDetail,
}
