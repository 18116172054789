import React from 'react';
import { DatePicker, Image } from 'antd';
import './index.css'; 

const CalendorDrop = () => {
    const onChange = (date, dateString) => {
        console.log(date, dateString);
    };

    return (
        <div>
            <DatePicker
                className="custom-date-picker"
                suffixIcon={<Image src='/assets/icons/h-calender.png' width={24} preview={false} />}
                onChange={onChange}
                allowClear={false}
            />
        </div>
    );
};

export { CalendorDrop };
