import { patientApi } from './PatientApi'
import { prescriptionApi } from './prescriptionApi'
import { forgotpasswordApi } from './ForgotPasswordApi'
import { ChangePassApi } from './ChangePassApi'

const apiCalls={
    patientApi,
    prescriptionApi,
    forgotpasswordApi,
    ChangePassApi,
}
export {apiCalls}