import React,{ useState }  from 'react'
import { Badge, Button, Image } from "antd"
import NotificationsDrawer from "../Notifications/NotificationsDrawer"

const Messages = () => {
    const [visible, setVisible]= useState(false)
  return (
    <>
    <Badge count={9} overflowCount={9}>
        <Button shape='circle' className='p-0 border0' onClick={()=> setVisible(true)}>
            <Image 
                src='/assets/icons/mail.png' 
                width={'22px'} 
                preview={false}
                alt="StoreApp" 
                className="up"
            />
        </Button>
    </Badge>
    <NotificationsDrawer
        visible={visible}
        onClose={()=> setVisible(false)}
    />
</>
  )
}

export {Messages}