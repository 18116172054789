import React from 'react'
import { Space, Card, Row, Col, Form, Typography, Image, DatePicker, Button } from 'antd'
import { HospiceInput } from '../input'
import { ActionButton, ModuleTopSmhead } from '../PageComponents'
import Dragger from 'antd/es/upload/Dragger'
import { DeleteOutlined } from '@ant-design/icons'

const AddPatient = ({view}) => {
    const [form] = Form.useForm()


    const props = {
        name: 'file',
        multiple: false,
      //   action: Urldomain + '/importDevices',
        headers: {
          // Authorization: `Bearer ${TokenUrl}`,
      },
        onChange(info) {
            const { file } = info;
            if (file.status !== 'uploading') {
                console.log(file, info.fileList);
                form.setFieldsValue({...form.getFieldsValue(),file:file?.originFileObj})
              }
        },
      }

    return (
        <div>
            <Card className='radius-12 border0'>
                <div className='mb-3'>
                    <Typography.Title level={5} className='brand-color'>
                        Add new patient
                    </Typography.Title>
                </div>
                <Form
                    form={form}
                    layout="vertical"
                    initialValues={{
                        remember: true,
                    }}
                    // onFinish={onFinish}
                >
                    <Space direction='vertical' size={15} className='w-100'>
                        <Card className='shadow-c border0 radius-12'> 
                            <Row gutter={16}>
                                <Col span={24}>
                                    <div className='mb-3'>
                                        <ModuleTopSmhead
                                            name='Personal Details'
                                        />
                                    </div>
                                </Col>
                                <Col span={24}>
                                    <div className='my-3 width-drag'>
                                        <Dragger {...props} className="dragcs">
                                            <p className="ant-upload-drag-icon">
                                                <Image src='/assets/icons/upload.png' width={'41.6px'} preview={false} />
                                            </p>
                                            <Typography.Text className="fs-12 grayish">Upload Profile Picture</Typography.Text>
                                        </Dragger>
                                    </div>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12}>
                                    <HospiceInput
                                        name='firstName'
                                        label='First name'
                                        placeholder='Enter first name'
                                        required
                                        message='Please enter first name'
                                        value={form.getFieldValue("firstName") || ''} 
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12}>
                                    <HospiceInput
                                        name='lastName'
                                        label='Last name'
                                        placeholder='Enter last name'
                                        required
                                        message='Please enter last name'
                                        value={form.getFieldValue("lastName") || ''} 
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12}>
                                    <HospiceInput
                                        name='email'
                                        label='Email address'
                                        placeholder='Enter email address'
                                        required
                                        message='Please enter email address'
                                        value={form.getFieldValue("email") || ''}
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12}>
                                    <HospiceInput
                                        name='phoneNumber'
                                        label='Phone number'
                                        placeholder='Enter phone number'
                                        required
                                        message='Please enter phone number'
                                        value={form.getFieldValue("phoneNumber") || ''} 
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12}>
                                    <Form.Item 
                                        name='dob'
                                        label="Date of birth" 
                                        placeholder='Enter date of birth'
                                        value={form.getFieldValue("dob") || ''}   
                                        rules={[
                                            {
                                              required: true,
                                              message: 'Please enter Date of birth',
                                            },
                                          ]}                                 
                                        >
                                        <DatePicker className='W-100' />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12}>
                                    <HospiceInput
                                        name='age'
                                        label='Age'
                                        placeholder='Enter age'
                                        required
                                        message='Please enter age'
                                        value={form.getFieldValue("age") || ''} 
                                    />
                                </Col>
                                <Col span={24}>
                                    <HospiceInput
                                        textArea
                                        name='address'
                                        label='Address'
                                        placeholder='Enter street address'
                                        required
                                        message='Please enter street address'
                                        value={form.getFieldValue("address") || ''}
                                    />
                                </Col>
                            </Row>
                        </Card>
                        <Card className='shadow-c border0 radius-12'> 
                            <Row gutter={16}>
                                <Col span={24}>
                                    <div className='mb-3'>
                                        <ModuleTopSmhead
                                            name='Caretaker Details'
                                        />
                                    </div>
                                </Col>
                                
                                <Col xs={24} sm={24} md={24} lg={12}>
                                    <HospiceInput
                                        name='cfirstName'
                                        label='First name'
                                        placeholder='Enter first name'
                                        required
                                        message='Please enter first name'
                                        value={form.getFieldValue("cfirstName") || ''} 
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12}>
                                    <HospiceInput
                                        name='clastName'
                                        label='Last name'
                                        placeholder='Enter last name'
                                        required
                                        message='Please enter last name'
                                        value={form.getFieldValue("clastName") || ''}
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12}>
                                    <HospiceInput
                                        name='cemail'
                                        label='Email address'
                                        placeholder='Enter email address'
                                        required
                                        message='Please enter email address'
                                        value={form.getFieldValue("cemail") || ''}
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12}>
                                    <HospiceInput
                                        name='cphoneNumber'
                                        label='Phone number'
                                        placeholder='Enter phone number'
                                        required
                                        message='Please enter phone number'
                                        value={form.getFieldValue("cphoneNumber") || ''}
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12}>
                                    <HospiceInput
                                        name='relationship'
                                        label='Relationship'
                                        placeholder='Enter your relationship with patient'
                                        required
                                        message='Please enter your relationship with patient'
                                        value={form.getFieldValue("relationship") || ''}
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12}>
                                    <HospiceInput
                                        name='secNumber'
                                        label='Sec. Number'
                                        placeholder='Enter secondary phone number'
                                        required
                                        message='Please enter secondary phone number'
                                        value={form.getFieldValue("secNumber") || ''}
                                    />
                                </Col>
                                <Col span={24}>
                                    <HospiceInput
                                        textArea
                                        name='caddress'
                                        label='Address'
                                        placeholder='Enter street address'
                                        required
                                        message='Please enter street address'
                                        value={form.getFieldValue("caddress") || ''}
                                    />
                                </Col>
                            </Row>
                        </Card>
                        <Card className='shadow-c border0 radius-12'> 
                            <Row gutter={16}>
                                <Col span={24}>
                                    <div className='mb-3'>
                                        <ModuleTopSmhead
                                            name='Medical Record'
                                        />
                                    </div>
                                </Col>
                                <Col span={24}>
                                    <div className='my-3 width-drag'>
                                        <Dragger {...props} className="dragcs">
                                            <p className="ant-upload-drag-icon">
                                                <Image src='/assets/icons/upload.png' width={'41.6px'} preview={false} />
                                            </p>
                                            <Typography.Text className="fs-12 grayish">Upload Medical Record</Typography.Text>
                                        </Dragger>
                                    </div>
                                </Col>
                                {
                                    view?
                                    <Col xs={24} sm={24} md={12}>
                                        <Space direction='vertical' size={10} className='w-100'>
                                            <div className='viewDownload space-between-align'>
                                                <Typography.Text className='gray-text fs-12'>
                                                    medical-reports.pdf (25.56 KB)
                                                </Typography.Text>
                                                <ActionButton
                                                    title='Delete'
                                                    icon={<DeleteOutlined className='danger-color' />}
                                                    onClick={() => {}}
                                                    type='ghost'
                                                    
                                                />
                                            </div>
                                            <div className='viewDownload space-between-align'>
                                                <Typography.Text className='gray-text fs-12'>
                                                    medical-reports.pdf (25.56 KB)
                                                </Typography.Text>
                                                <ActionButton
                                                    title='Delete'
                                                    icon={<DeleteOutlined className='danger-color' />}
                                                    onClick={() => {}}
                                                    type='ghost'
                                                />
                                            </div>
                                        </Space>
                                    </Col>
                                    :
                                    <></>
                                }
                            </Row>
                        </Card>
                        <Card className='shadow-c border0 radius-12'> 
                            <Row gutter={16}>
                                <Col span={24}>
                                    <div className='mb-3'>
                                        <ModuleTopSmhead
                                            name='Identity Card'
                                        />
                                    </div>
                                </Col>
                                <Col span={24}>
                                    <div className='my-3 width-drag'>
                                        <Dragger {...props} className="dragcs">
                                            <p className="ant-upload-drag-icon">
                                                <Image src='/assets/icons/upload.png' width={'41.6px'} preview={false} />
                                            </p>
                                            <Typography.Text className="fs-12 grayish">Upload Identity Card</Typography.Text>
                                        </Dragger>
                                    </div>
                                </Col>
                                {
                                    view?
                                    <Col xs={24} sm={24} md={12}>
                                        <Space direction='vertical' size={10} className='w-100'>
                                            <div className='viewDownload space-between-align'>
                                                <Typography.Text className='gray-text fs-12'>
                                                    CNIC Front.pdf (25.56 KB)
                                                </Typography.Text>
                                                <ActionButton
                                                    title='Delete'
                                                    icon={<DeleteOutlined className='danger-color' />}
                                                    onClick={() => {}}
                                                    type='ghost'
                                                    
                                                />
                                            </div>
                                            <div className='viewDownload space-between-align'>
                                                <Typography.Text className='gray-text fs-12'>
                                                    CNIC Back.pdf (25.56 KB)
                                                </Typography.Text>
                                                <ActionButton
                                                    title='Delete'
                                                    icon={<DeleteOutlined className='danger-color' />}
                                                    onClick={() => {}}
                                                    type='ghost'
                                                />
                                            </div>
                                        </Space>
                                    </Col>
                                    :
                                    <></>
                                }
                            </Row>
                        </Card>
                        <Space className="w-100" style={{justifyContent:'end'}}>
                            <Button 
                            >
                                Cancel
                            </Button>
                            <Button  
                                type="primary"
                                block
                                htmlType="submit"
                                >
                                Save
                            </Button>
                        </Space>
                    </Space>
                </Form>
            </Card>
        </div>
    )
}

export {AddPatient}