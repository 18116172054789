import React, { useState } from "react"
import { Badge, Button, Image } from "antd"
import NotificationsDrawer from "./NotificationsDrawer"

export const Notifications = () => {
    
    const [visible, setVisible]= useState(false)
    return (
        <>
            <Badge count={9} overflowCount={9}>
                  <Button shape='circle' className='p-0 border0' onClick={()=> setVisible(true)}>
                      <Image 
                          src='/assets/icons/bell.png' 
                          width={'26px'} 
                          preview={false}
                          alt="StoreApp" 
                          className="up"
                      />
                  </Button>
              </Badge>
            <NotificationsDrawer
                visible={visible}
                onClose={()=> setVisible(false)}
            />
        </>
    )
}