import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl } from '../../../constants'
import { checkAuthorization } from '../../../helpers';
import { message } from 'antd'


export const getPatientPrescriptionDetail = createAsyncThunk('getPatientStatistics',
    async (object, { dispatch, getState }) => {

        const { userToken } = checkAuthorization()
        dispatch(gettingPatientPrescriptionDetail())
        var myHeaders = new Headers();
        myHeaders.append("Authorization", userToken)
        myHeaders.append("Content-Type", "application/json")
        myHeaders.append("pageNO", object?.pageNo)
        myHeaders.append("recordsPerPage", object?.pageSize)
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({ id: object?.id, order: object?.order, name: object?.name })
        };
        fetch(domainUrl + `/viewNursePatientsFromPrescreption`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result?.success) {
                    dispatch(prescriptionpatientDetailResponse(result?.data))
                    dispatch(setPages({ pageNo: object?.pageNo, pageSize: object?.pageSize, totalRecords: result?.totalRecords }))
                }
                else
                    throw result?.message
            })
            .catch(error => {
                dispatch(prescritionpatientDetailClear())
                message.error(error)
            })
    }
)


const PatientPresciptionSlice = createSlice({
    name: 'patientbyprescription',
    initialState: {
        data: null,
        loading: false,
        patientprescriptionDetail: null,
        loadingPatientPrescDetail: false,
        pagination: {
            pageNo: 1,
            pageSize: 10,
            totalRecords: 0,
        },
        filters: {
            title: '',
            order: 1,
            id: '',
            status: '',
        },
    },
    reducers: {
        getPatientStatistics: state => {
            state.loading = true
            state.data = null
        },
        statisticsResponse: (state, action) => {
            state.data = action.payload
            state.loading = false
        },
        statisticsClear: (state) => {
            state.data = null
            state.loading = false
        },
        gettingPatientPrescriptionDetail: state => {
            state.loadingPatientPrescDetail = true
            state.patientprescriptionDetail = null
        },
        prescriptionpatientDetailResponse: (state, action) => {
            state.patientprescriptionDetail = action.payload
            state.loadingPatientPrescDetail = false
        },
        prescritionpatientDetailClear: (state) => {
            state.patientprescriptionDetail = null
            state.loadingPatientPrescDetail = false
        },
        changePatientPrescriptionDetailFilters: (state, action) => {
            state.filters = action.payload
        },
        setPages: (state, action) => {
            state.pagination = action.payload
        },
    },
})

export const {
    gettingaddboxStatistics, statisticsResponse, statisticsClear,
    gettingPatientPrescriptionDetail, prescriptionpatientDetailResponse, prescritionpatientDetailClear,
    changePatientPrescriptionDetailFilters, setPages
} = PatientPresciptionSlice.actions;
export default PatientPresciptionSlice.reducer;
