import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { domainUrl } from '../../../constants';
import { checkAuthorization } from '../../../helpers';
import { message } from 'antd';

export const getAddServicepatientDetail = createAsyncThunk(
    'getServicepatientStatistics',
    async (object, { dispatch }) => {
        const { userToken } = checkAuthorization();
        dispatch(gettingAddServicepatientDetail());

        const myHeaders = new Headers();
        myHeaders.append('Authorization', userToken);
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append("pageNO", object?.pageNo)
        myHeaders.append("recordsPerPage", object?.pageSize)
        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({ id: object.id, order: object.order, name: object.name }),
        };

        try {
            const response = await fetch(domainUrl + '/viewAllPrescriptionForNurse', requestOptions);
            const result = await response.json();

            if (result.success) {
                let filteredData = result.data;

                // Apply search filter
                if (object.name) {
                    const searchTerm = object.name.toLowerCase();
                    filteredData = filteredData.filter(item => {
                        const nurseName = `${item.nurse.firstName} ${item.nurse.lastName}`.toLowerCase();
                        return nurseName.includes(searchTerm);
                    });
                }

                // Apply order sorting
                if (object.order === '1') {
                    filteredData.sort((a, b) => {
                        const nameA = `${a.nurse.firstName} ${a.nurse.lastName}`.toLowerCase();
                        const nameB = `${b.nurse.firstName} ${b.nurse.lastName}`.toLowerCase();
                        if (nameA < nameB) return -1;
                        if (nameA > nameB) return 1;
                        return 0;
                    });
                } else if (object.order === '0') {
                    filteredData.sort((a, b) => {
                        const nameA = `${a.nurse.firstName} ${a.nurse.lastName}`.toLowerCase();
                        const nameB = `${b.nurse.firstName} ${b.nurse.lastName}`.toLowerCase();
                        if (nameA > nameB) return -1;
                        if (nameA < nameB) return 1;
                        return 0;
                    });
                }

                dispatch(addservicepatientDataResponse(result.patient));
                dispatch(addservicepatientDetailResponse(filteredData));
                dispatch(
                    setPages({
                        pageNo: object.pageNo,
                        pageSize: object.pageSize,
                        totalRecords: filteredData.length,
                    })
                );
            } else {
                throw result.message;
            }
        } catch (error) {
            dispatch(addservicepatientDetailClear());
            message.error(error);
        }
    }
);

const AddServicePatientSlice = createSlice({
    name: 'prescriptionservicepatient',
    initialState: {
        data: null,
        loading: false,
        addservicepatientDetail: [],
        patientdata: null,
        loadingAddservicepatientDetail: false,
        pagination: {
            pageNo: 1,
            pageSize: 10,
            totalRecords: 0,
        },
        filters: {
            name: '',
            order: 1,
            id: '',
            status: '',
        },
    },
    reducers: {
        gettingAddServicepatientDetail: (state) => {
            state.loadingAddservicepatientDetail = true;
        },
        addservicepatientDetailResponse: (state, action) => {
            state.addservicepatientDetail = action.payload;
            state.loadingAddservicepatientDetail = false;
        },
        addservicepatientDataResponse: (state, action) => {
            state.patientdata = action.payload;
            state.loadingAddservicepatientDetail = false;
        },
        addservicepatientDetailClear: (state) => {
            state.addservicepatientDetail = [];
            state.loadingAddservicepatientDetail = false;
            state.patientdata = null;
        },
        changeAddservicepatientDetailFilters: (state, action) => {
            state.filters = action.payload;
        },
        setPages: (state, action) => {
            state.pagination = action.payload;
        }
    },
});

export const {
    gettingAddServicepatientDetail,
    addservicepatientDetailResponse,
    addservicepatientDataResponse,
    addservicepatientDetailClear,
    changeAddservicepatientDetailFilters,
    setPages,
} = AddServicePatientSlice.actions;

export default AddServicePatientSlice.reducer;
