import React, { useState, useEffect } from 'react';
import { useNavigate, Route, Routes, useLocation} from 'react-router-dom'
import './index.css'
import { Layout, Menu, Image, Space } from 'antd';
import { Dashboard, Login, Patients, Planner } from '../../pages';
import { CalendorDrop, Messages, Notifications, UserProfile } from '../../components/Header';
import { AddPatient, MessageBox } from '../../components';
const { Header, Sider, Content } = Layout

const RouteF = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const [items, setItems] = useState([])
  const [currentTab, setCurrentTab]= useState('1')
  function getItem(label, key, icon, onClick, children, type, isActive) {
    return { key, icon, onClick, children, label, type, isActive }
  }


  useEffect(()=>{
    let tab= location?.pathname?.split("/")[1]
    tab= tab==='' ? '1':
        tab==='planner' ? '2':
        tab==='patient' ? '3':
        tab==='addpatient' ? '3':
        '1'
    setCurrentTab(tab)
  }, [location])


  useEffect(() => {
    let itemsArr = []
      itemsArr.push(getItem('Planner', '2', 
      currentTab === '2'?
          <img src="/assets/icons/active-dash.png" width={'20px'} preview={false} alt="" style={{ color: 'red' }} />:
          <img src="/assets/icons/inactive-planner.png" width={'20px'} preview={false} alt="" />, 
          () => { navigate("/planner", { replace: true }) },
          null,
          null,
          currentTab === '2'
      ))
      itemsArr.push(
            getItem(
              'Dashboard',
              '1',
              currentTab === '1' ? 
                <img src="/assets/icons/active-dash.png" width={'20px'} preview={false} alt="" style={{ color: 'red' }} /> :
                <img src="/assets/icons/inactive-dash.png" width={'20px'} preview={false} alt="" />,
              () => { navigate("/", { replace: true }) },
              null,
              null,
              currentTab === '1'
            )
          );
      itemsArr.push(getItem('Patients', '3', 
      currentTab === '3'?
          <img src="/assets/icons/active-patient.png" width={'20px'} preview={false} alt="" style={{ color: 'red' }} />:
          <img src="/assets/icons/inactive-patient.png" width={'20px'} preview={false} alt="" /> , 
          () => { navigate("/patient", { replace: true }) },
          null,
          null,
          currentTab === '3'
      ))
      setItems([...itemsArr])
    }, [currentTab, navigate])
  return (
    <Layout style={{ height: '100vh'}}>
      <Sider trigger={null} collapsible collapsed={collapsed} style={{ height: '100vh', overflowY: 'auto',background:"#fff" }}>
        <div className="logo" style={{ display: 'flex', justifyContent: 'center' }}>
          <Image
            style={{width: collapsed?"100%":'100px'}}
            height={'auto'}
            src="./assets/logo.png"
            alt='Medical Director Panel'
            
            preview= {false}
          />
        </div>
        <Menu
          defaultSelectedKeys={['1']}
          selectedKeys={[currentTab]}
          mode="inline"
          theme="light"
          inlineCollapsed={collapsed}
          items={items}
          className='listitem'
        />
      </Sider>
      <Layout className="site-layout">
        <Header
          className="site-layout-background"
          style={{
            padding: 0,
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <div style={{ width: '95%', display: 'flex', justifyContent: 'space-between' }}>
            <div className= 'trigger border-0-btn' onClick={() => setCollapsed(!collapsed)}>
              {
                collapsed ? 
                <Image src='/assets/icons/collaps.png' width={'35.46px'} preview={false} />
                : 
                <Image src='/assets/icons/collaps.png' width={'35.46px'} preview={false} />
              }
            </div>
            <Space size={15}>
              <Image src='/assets/icons/bright.png' width={24} preview={false} />
              <CalendorDrop />
              <Image src='/assets/icons/quest-mark.png' width={24} preview={false} />
              <Messages />
              <Notifications />
              <UserProfile />
            </Space>
          </div>
        </Header>

        <Content
          className=" scroll-bar"
          style={{
            // margin: '24px 16px',
            padding: 24,
            minHeight: 280,
            overflowY: 'auto'
          }}
        >
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/login" element={<Login />} />
              <Route path="/planner" element={<Planner />} />
              <Route path="/patient" element={<Patients />} />
              <Route path="/addpatient" element={<AddPatient />} />
            </Routes>
        </Content>
      </Layout>
    </Layout>
  )
}

export default RouteF
