import React, { useEffect, useState } from 'react'
import { Image, Space, Row, Col, Form, Typography,Dropdown, Card, Table, Button, Flex, Switch,} from 'antd'
import { EyeOutlined, FilterOutlined } from '@ant-design/icons';
import { MyInput } from '../../Forms';
import { AlertModal, SendMsgModal } from '../../Modals';
import { ViewPateintDrawer } from '../../ViewPateintDrawer';
import { ActionButton } from '../../PageComponents';
import { actionsApi, TableLoader } from '../../../shared';
import { useDispatch, useSelector } from 'react-redux';

const { Text } = Typography

let timer;


const PatientByAppointmentTable = () => {

    const [form] = Form.useForm()
    const [ visible, setVisible ] = useState(false)    
    const dispatch = useDispatch();
    const { patientappointmentDetail, loadingPatientAppointDetail, filters, pagination } = useSelector(state => state?.patientbyappointment);
    const [ visiblemodal, setVisibleModal] = useState(false)
    const [prespatientid ,setPresPatientId ] = useState(null)
    const [ tableshow ,setTableShow ] = useState(false)
    const [order, setOrder] = useState(1);

    const items = [
        { label: <a href="#">A-Z</a>, key: '0' },
        { label: <a href="#">Z-A</a>, key: '1' },
    ];

    useEffect(() => {
        dispatch(actionsApi?.getPatientAppointmentDetail({ ...filters, ...pagination, pageNo: 1 }));
        form.setFieldsValue({ ...filters });
    }, []);

    const filter = (order) => {
        let data = form.getFieldsValue();
        dispatch(actionsApi?.getPatientAppointmentDetail({ ...data, order, ...pagination, pageNo: 1 }));
        dispatch(actionsApi?.changePatientAppointmentDetailFilters({ ...data, order }));
    };

    const call = (pageNo = 1, pageSize = 10) => {
        dispatch(actionsApi?.getPatientAppointmentDetail({ ...filters, pageNo, pageSize }));
    };

    function searchHandler(name, pageNo = 1, pageSize = 20) {
        dispatch(actionsApi?.getPatientAppointmentDetail({ ...filters, name, pageNo, pageSize }));
    }

    const onClick = ({ key }) => {
        key = parseInt(key) + 1;
        setOrder(key);
        filter(key);
    };

    const debounce = (func, delay) => {
        return function(...args) {
            clearTimeout(timer);
            timer = setTimeout(() => {
                func.apply(this, args);
            }, delay);
        };
    };

    const debouncedSearchHandler = debounce(searchHandler, 400);

  
  
    const columns = [
        {
            title: 'Patient Name',
            dataIndex: 'firstName',
            render:(_,row) => `
                ${row?.firstName.charAt(0).toUpperCase() + row?.firstName.slice(1)} 
                ${row?.lastName.charAt(0).toUpperCase() + row?.lastName.slice(1)}`
        },
        {
            title: 'Age',
            dataIndex: 'age',
            key: 'age',
        },
        {
            title: 'Disease',
            dataIndex: 'note',
            key: 'note',
        },
        {
            title: 'Time Stamp',
            dataIndex: 'dateTime',
            key: 'dateTime',
        },
        {
            title: <Text>Action</Text>,
            key: 'action',
            fixed: 'right',
            align:'right',
            width: 150,
            render: (_, row) => (
                <Space>                    
                    <ActionButton
                        title='View Item'
                        icon={<EyeOutlined />}
                        onClick={() => { setVisible(true); setPresPatientId(row?.id);setTableShow(false) }}
                        type='ghost'
                    />
                    <ActionButton
                        title='Prescription'
                        icon={<Image src='/assets/icons/prescription.png' width={18} preview={false} />}
                        onClick={() => { setVisible(true); setPresPatientId(row?.id); setTableShow(true) }}
                        type='ghost'
                    />
                </Space>
            ),
        },
    ];
    

  return (
    <div>
        <Row gutter={[12,12]}>
            <Col span={24}>
                <Form
                    form={form}
                    layout="vertical" 
                >
                    <Flex gap={'small'} className='w-100' align='end'>
                        <MyInput
                            name='search'
                            label='Search'
                            placeholder='Search'
                            value={form.getFieldValue("search") || ''} 
                            className='w-100'
                            onChange={(e) => debouncedSearchHandler(e.target.value, 1, 20)}
                        />
                        <Dropdown
                            menu={{
                                items,
                                onClick
                            }}
                            trigger={['click']}
                            arrow
                            icon={<FilterOutlined />}
                            className='margin-top'
                        >
                            <Button
                                icon={<FilterOutlined />}
                            >
                                Filter
                            </Button>
                        </Dropdown>
                    </Flex>
                </Form>
            </Col>
            <Col span={24}>
                <Table
                    size='large'
                    columns={columns}
                    dataSource={patientappointmentDetail}
                    scroll={{ x: 1000 }}
                    rowKey="id"
                    pagination={{
                        hideOnSinglePage: true,
                        total: pagination?.totalRecords,
                        pageSize: pagination?.pageSize,
                        defaultPageSize: pagination?.pageSize,
                        current: pagination?.pageNo,
                        size: "default",
                        pageSizeOptions: ['10', '20', '50', '100'],
                        onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                        showTotal: (total) => <Button>Total: {total}</Button>,
                    }}
                    loading={{ ...TableLoader, spinning: loadingPatientAppointDetail }}
                />
            </Col>
        </Row>
        <ViewPateintDrawer
            visible={visible}
            tableshow={tableshow}
            prespatientid={prespatientid}
            onClose={()=>{setVisible(false);setPresPatientId(null);setTableShow(false)}}
        />
        <SendMsgModal 
            visiblemodal={visiblemodal}
            onClose={()=>{setVisibleModal(false)}}
        />
    </div>
  )
}

export {PatientByAppointmentTable}