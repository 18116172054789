import { Button, Checkbox, Flex, Form, Space, Table } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionsApi, TableLoader } from '../../../../shared';
import { apiCalls } from '../../../../shared/Apis';

const TimePrescriptionTable = ({ presid, onClose }) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { todaymedicine, loadingViewMedDetail } = useSelector(state => state?.viewmedicineprescription);
    const [valuecheck, setValueCheck] = useState(1);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (presid) {
            dispatch(actionsApi?.getViewMedpresDetail({ id: presid?.id }));
        }
    }, [presid, dispatch]);

    const handleChange = (checked) => {
        const statustime = checked ? 2 : 1;
        setValueCheck(statustime);
    };

    const getCurrentTimeSlot = () => {
        const currentTime = moment();
        const morningStart = moment('09:00 AM', 'hh:mm A');
        const morningEnd = moment('12:00 PM', 'hh:mm A');
        const afternoonStart = moment('12:00 PM', 'hh:mm A');
        const afternoonEnd = moment('05:00 PM', 'hh:mm A');
        const nightStart = moment('05:00 PM', 'hh:mm A');
        const nightEnd = moment('09:00 PM', 'hh:mm A');

        if (currentTime.isBetween(morningStart, morningEnd)) {
            return 'morning';
        } else if (currentTime.isBetween(afternoonStart, afternoonEnd)) {
            return 'afternoon';
        } else if (currentTime.isBetween(nightStart, nightEnd)) {
            return 'night';
        }
        return 'none';
    };

    const currentSlot = getCurrentTimeSlot();

    const columns = [
        {
            title: 'Today',
            dataIndex: 'date',
            render: (date) => (
                <div>
                    {moment(date).format('MMM Do YYYY')}
                </div>
            ),
            width: 200
        },
        {
            title: 'Morning',
            dataIndex: 'morning',
            align: 'center',
            render: (morning) => (
                <>
                    {morning === '1' ? (
                        <Form.Item>
                            <Checkbox
                                onChange={handleChange}
                                disabled={currentSlot !== 'morning'}
                            />
                        </Form.Item>
                    ) : morning === '2' ? (
                        <Form.Item>
                            <Checkbox checked disabled />
                        </Form.Item>
                    ) : null}
                </>
            )
        },
        {
            title: 'Afternoon',
            dataIndex: 'afterNoon',
            align: 'center',
            render: (afterNoon) => (
                <>
                    {afterNoon === '1' ? (
                        <Form.Item>
                            <Checkbox
                                onChange={handleChange}
                                disabled={currentSlot !== 'afternoon'}
                            />
                        </Form.Item>
                    ) : afterNoon === '2' ? (
                        <Form.Item>
                            <Checkbox checked disabled />
                        </Form.Item>
                    ) : null}
                </>
            )
        },
        {
            title: 'Night',
            dataIndex: 'night',
            align: 'center',
            render: (night) => (
                <>
                    {night === '1' ? (
                        <Form.Item>
                            <Checkbox
                                onChange={handleChange}
                                disabled={currentSlot !== 'night'}
                            />
                        </Form.Item>
                    ) : night === '2' ? (
                        <Form.Item>
                            <Checkbox checked disabled />
                        </Form.Item>
                    ) : null}
                </>
            )
        },
    ];

    const updatePrescriptionApi = async (formData) => {
        setLoading(true);
        try {
            const result = await apiCalls.prescriptionApi.updatePrescriptionApi({
                ...formData,
                medicines: todaymedicine.map(medicine => ({
                    id: medicine.id
                }))
            });
            setLoading(false);
            if (result) onClose();
        } catch (error) {
            console.error("Error updating data:", error);
            setLoading(false);
        }
    };

    const onFinish = (formData) => {
        updatePrescriptionApi(formData);
    };

    return (
        <div>
            <Form
                form={form}
                onFinish={onFinish}
            >
                <Table
                    size='large'
                    columns={columns}
                    dataSource={todaymedicine}
                    scroll={{ x: 500, y: 300 }}
                    pagination={false}
                    className='mb-3'
                    loading={{ ...TableLoader, spinning: loadingViewMedDetail }}
                />
                <Flex justify='end' gap={5} >
                    <Button onClick={onClose}>Cancel</Button>
                    <Button
                        type="primary"
                        danger
                        loading={loading}
                        htmlType='submit'
                        disabled={!todaymedicine || todaymedicine.length === 0}
                    >
                        Update
                    </Button>
                </Flex>
            </Form>
        </div>
    );
};

export { TimePrescriptionTable };
