import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl } from '../../../constants'
import { checkAuthorization } from '../../../helpers';
import { message } from 'antd'

export const getPatientAppointmentDetail = createAsyncThunk('getPatientappoinmentStatistics',
    async (object, { dispatch, getState }) => {

        const { userToken } = checkAuthorization()
        dispatch(gettingPatientAppointmentDetail())
        var myHeaders = new Headers();
        myHeaders.append("Authorization", userToken)
        myHeaders.append("Content-Type", "application/json")
        myHeaders.append("pageNO", object?.pageNo)
        myHeaders.append("recordsPerPage", object?.pageSize)
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({ id: object?.id, order: object?.order, name: object?.name })
        };
        fetch(domainUrl + `/viewNursePatientsFromAppointments`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result?.success) {
                    dispatch(appointmentpatientDetailResponse(result?.data))
                    dispatch(setPages({ pageNo: object?.pageNo, pageSize: object?.pageSize, totalRecords: result?.totalRecords }))
                }
                else
                    throw result?.message
            })
            .catch(error => {
                dispatch(appointmentpatientDetailClear())
                message.error(error)
            })
    }
)


const PatientAppointmentSlice = createSlice({
    name: 'patientbyappointment',
    initialState: {
        data: null,
        loading: false,
        patientappointmentDetail: null,
        loadingPatientAppointDetail: false,
        pagination: {
            pageNo: 1,
            pageSize: 10,
            totalRecords: 0,
        },
        filters: {
            title: '',
            order: 1,
            id: '',
            status: '',
        },
    },
    reducers: {
        getPatientStatistics: state => {
            state.loading = true
            state.data = null
        },
        statisticsResponse: (state, action) => {
            state.data = action.payload
            state.loading = false
        },
        statisticsClear: (state) => {
            state.data = null
            state.loading = false
        },
        gettingPatientAppointmentDetail: state => {
            state.loadingPatientAppointDetail = true
            state.patientappointmentDetail = null
        },
        appointmentpatientDetailResponse: (state, action) => {
            state.patientappointmentDetail = action.payload
            state.loadingPatientAppointDetail = false
        },
        appointmentpatientDetailClear: (state) => {
            state.patientappointmentDetail = null
            state.loadingPatientAppointDetail = false
        },
        changePatientAppointmentDetailFilters: (state, action) => {
            state.filters = action.payload
        },
        setPages: (state, action) => {
            state.pagination = action.payload
        },
    },
})

export const {
    gettingaddboxStatistics, statisticsResponse, statisticsClear,
    gettingPatientAppointmentDetail, appointmentpatientDetailResponse, appointmentpatientDetailClear,
    changePatientAppointmentDetailFilters, setPages
} = PatientAppointmentSlice.actions;
export default PatientAppointmentSlice.reducer;
