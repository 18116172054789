import React from 'react'
import { AppTable, AppointConsultComponent, CardComponent, BarChartComponent, PatientByPrescriptionTable, ModuleTopHeading } from '../../components'
import { Card, Col, Row, Space } from 'antd'

const Dashboard = () => {
  return (
    <Space direction='vertical' size={20} className='w-100'>
        <CardComponent />
        <BarChartComponent />
        <AppointConsultComponent />
        <Card className='radius-12 border0 shadow-in'>
            <Row gutter={[12,12]}>
                <Col span={12}>
                    <ModuleTopHeading
                        name='Patients'
                    />
                </Col>
                <Col span={24}>
                    <PatientByPrescriptionTable />
                </Col>
            </Row>
        </Card>
    </Space>
  )
}

export {Dashboard}