import { configureStore } from "@reduxjs/toolkit";
import {
	login,
	patientbyprescription,
	patientbyappointment,
	cardapi,
	chartapi,
	dashboard,
	prescriptionservicepatient,
	viewmedicineprescription,
} from "./action"
const store = configureStore({
	reducer: {
		login,
		patientbyprescription,
		patientbyappointment,
		cardapi,
		chartapi,
		dashboard,
		prescriptionservicepatient,
		viewmedicineprescription,
	},
})
export default store