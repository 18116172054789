import React, { useState } from "react"
import { Drawer, Button, message, Flex, Form, Row, Col} from "antd"
import "./index.css"
import { MyInput } from "../../Forms"
import { checkAuthorization, domainUrl } from "../../../shared"
import { apiCalls } from "../../../shared/Apis"

const UserProfileDrawer= ({visible, onClose})=>{

    const [loading, setLoading]= useState(false)
    const [loadingpass, setLoadingPass]= useState(false)
    const [form] = Form.useForm()

    const logout = () => {
        setLoading(true)
        const {userToken}= checkAuthorization()
        var myHeaders = new Headers();
        myHeaders.append("Authorization", userToken)
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        }
        fetch(domainUrl + '/logout', requestOptions)
        .then(response => response.json())
        .then(result => {
        if (result?.success)
            {
                localStorage.clear()
                window.location.href = '/'
            }
        else
            throw 'error'
        })
        .catch(() => {
            setLoading(false)
            localStorage.clear()
            window.location.href = '/'
        })
    }


   
    
    const changePasswordPost = async() => {
        setLoadingPass(true)
        const formData = await form.validateFields();
        try {
            const result = await apiCalls.ChangePassApi.changePasswordPost(formData)
            setLoadingPass(false);
            if(result){
                form.resetFields();
                onClose()
            }
        } catch (error){
            console.error("Error change password data",error)
            setLoadingPass(false);
        }
    }


    return (
        <Drawer
            title='Profile'
            onClose={onClose}
            open={visible}
            width={400}
            footer={
                <Flex justify="space-between">
                    <Button
                        type="primary"
                        loading={loading}
                        onClick={logout}
                    >
                        Logout
                    </Button>
                    <Button  
                        type="primary"
                        loading={loadingpass}
                        htmlType="submit"
                        onClick={()=>form.submit()}
                        >
                        Change Password
                    </Button>
                </Flex>
            }
        >
            <Form
                form={form}
                layout="vertical" 
                className='mb-3'
                onFinish={changePasswordPost}
            >
                <Row gutter={[16,16]}>
                    <Col span={24}>
                        <MyInput
                            name='oldPassword'
                            type='password'
                            label='Old Password'
                            placeholder='Enter your old password'
                            required
                            message='please enter old password'
                            value={form.getFieldValue("oldPassword") || ''} 
                            className='w-100'
                        />
                    </Col>
                    <Col span={24}>
                        <MyInput
                            name='newPassword'
                            type='password'
                            label='New Password'
                            placeholder='Enter your new password'
                            required
                            message={()=>{}}
                            value={form.getFieldValue("newPassword") || ''} 
                            className='w-100'
                            rules={[
                                {
                                    required: true,
                                }
                            ]}
                            validator={({ getFieldValue }) => ({
                                validator: (_, value) => {
                                    const reg = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*\d).{8,}$/;
                                    if (!reg.test(value)) {
                                        return Promise.reject(new Error('Password should contain at least 8 characters, one uppercase letter, one number, one special character'));
                                    } else {
                                        return Promise.resolve();
                                    }
                                }
                            })}
                        />
                    </Col>
                    <Col span={24}>
                        <MyInput
                            name='confirmPassword'
                            type='password'
                            label='Confirm Password'
                            placeholder='Enter your confirm password'
                            dependencies={['newPassword']}
                            required
                            message='Please enter confirm password'
                            value={form.getFieldValue("confirmPassword") || ''} 
                            className='w-100'
                            rules={[
                                {
                                    required: true,
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('newPassword') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('The password that you entered do not match!'));
                                    },
                                }),
                            ]}
                            validator={({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('newPassword') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('The password that you entered do not match!'));
                                },
                            })}
                        />
                    </Col>
                </Row>
            </Form>
        </Drawer>
    )
}
export default UserProfileDrawer