import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl } from '../../../constants'
import { checkAuthorization } from '../../../helpers';
import { message } from 'antd'

export const getViewMedpresDetail = createAsyncThunk('getViewMedStatistics',
    async (object, { dispatch, getState }) => {

        const {userToken}= checkAuthorization()
        dispatch(gettingViewMedDetail())
        var myHeaders = new Headers();
        myHeaders.append("Authorization", userToken)
        myHeaders.append("Content-Type", "application/json")
        myHeaders.append("pageNO", object?.pageNo)
        myHeaders.append("recordsPerPage", object?.pageSize)
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify()
        };
        fetch(domainUrl + `/viewSinglePresescriptionForNurse/${object?.id}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result?.success) {
                    dispatch(viewmedDetailResponse(result?.data))
                    dispatch(viewmedTodayResponse(result?.todayPrescription))
                    dispatch(nurseDetailResponse(result?.nurse))
                    console.log(result?.data)
                }
                else 
                    throw result?.message
            })
            .catch(error => { 
                dispatch(viewmedDetailClear())
                message.error(error) 
            })
    }
)

const ViewMediSlice = createSlice({
    name: 'viewmedicineprescription',
    initialState: {
        data: null,
        loading: false,
        nursedetail:null,
        todaymedicine:null,
        addviewmedDetail: null,
        loadingViewMedDetail: false
    },
    reducers: {
        getPatientStatistics: state => {
            state.loading=true
            state.data=null
        },
        statisticsResponse: (state, action)=>{
            state.data= action.payload
            state.loading= false
        },
        statisticsClear: (state)=>{
            state.data= null
            state.loading= false
        },
        gettingViewMedDetail: state => {
            state.loadingViewMedDetail=true
            state.addviewmedDetail=null
        },
        nurseDetailResponse: (state, action) => {
            state.nursedetail = action.payload;
            state.loadingAddservicepatientDetail = false;
        },
        viewmedDetailResponse: (state, action)=>{
            state.addviewmedDetail= action.payload
            state.loadingViewMedDetail= false
        },
        viewmedTodayResponse: (state, action)=>{
            state.todaymedicine= action.payload
            state.loadingViewMedDetail= false
        },
        viewmedDetailClear: (state)=>{
            state.nursedetail= null
            state.addviewmedDetail= null
            state.todaymedicine= null
            state.loadingViewMedDetail= false
        }
    },
})
export const { 
    gettingaddboxStatistics, statisticsResponse, statisticsClear,
    gettingViewMedDetail, viewmedDetailResponse, viewmedDetailClear,nurseDetailResponse,viewmedTodayResponse
} = ViewMediSlice.actions;
export default ViewMediSlice.reducer;